<template>
  <div class="encryptionRule">
    <v-list
      ref="list"
      :isAdd="true"
      :isOperateColumn="false"
      :headers="headers"
      :tableUrl="tableUrl"
      :searchParam="searchParam"
      :extraParams="extraParams"
    >
      <template #headBtnSlot>
        <!-- <v-button text="导入" @click="toAdd"></v-button>
        <v-button text="导出" @click="toAdd"></v-button> -->
        <!-- <v-button text="新增" @click="toAdd"></v-button> -->
      </template>
      <template #searchSlot>
        <v-input
          label="规则名称"
          clearable
          @change="$refs.list.search()"
          v-model="searchParam.title"
        />
      </template>
    </v-list>
  </div>
</template>

<script>
import { totalSecurityUrl } from "./api.js";

export default {
  name: "encryptionRule",
  data() {
    return {
      wordsOptions: [],
      searchParam: {
        title: null,
      },
      headers: [
        {
          prop: "code",
          label: "编码",
        },
        {
          prop: "title",
          label: "规则名称",
        },
        {
          prop: "content",
          label: "规则描述",
        },
      ],
      extraParams: {
        type: 31,
      },
      tableUrl: totalSecurityUrl,
    };
  },
  mounted() {},
  methods: {},
};
</script>
<style lang="less" scoped>
.encryptionRule {
  box-sizing: border-box;
  height: 100%;
}
</style>
